<template>
  <div class="vc-page-bg">
    <!--    <div class="main-title">-->
    <!--      <p class="px-2" v-if="!isEndCall && callStep == steps.VIEW_INSTRUCTIONS">BẠN VUI LÒNG ĐỌC HƯỚNG DẪN DƯỚI ĐÂY TRƯỚC KHI THỰC HIỆN GỌI VIDEO-->
    <!--      </p></div>-->
    <div class="main-body">
      <div class="vc-modal">
        <div class="detail-instructions">
          <h2 class="title-instruction">
            ĐĂNG KÝ/ CẬP NHẬT THÔNG TIN THUÊ BAO
          </h2>
          <ul>
            <li><b>Bạn vui lòng thực hiện theo hướng dẫn sau:</b></li>
            <li>
              <b>Bước 1:</b> Để khuôn mặt vào giữa khung hình và bấm
              <b class="text-primary">Bắt đầu quay</b>
            </li>
            <li class="rounded p-1" style="border: 2px solid #ed1f24">
              <b>Bước 2:</b> Đọc số thuê bao cần Đăng ký/cập nhật<br /><span>
                Số thuê bao:<b class="text-primary font-large-1">
                  {{ $route.params.phone.replace("84", "0") }}</b
                ></span
              >
            </li>
            <li>
              <b>Bước 3:</b> Bấm <b class="text-primary">Gửi Video</b> để hoàn
              tất
            </li>
          </ul>
        </div>
        <div class="font-medium-2" style="position: relative">
          <video
            :class="isRecorded ? 'd-none' : ''"
            autoplay
            muted
            playsinline
            id="videoLive"
            class="w-100"
          ></video>
          <video
            :class="isRecorded ? '' : 'd-none'"
            controls
            playsinline
            id="videoRecorded"
            class="w-100"
          ></video>
          <div
            style="position: absolute; bottom: 1rem"
            class="d-flex justify-content-center w-100 font-medium-4"
            v-if="!isRecorded"
          >
            <button
              class="record-button"
              @click="RecordVideo"
              :class="isRecording ? 'bg-green' : ''"
            >
              {{ isRecording ? "Đang ghi hình" : "Bắt đầu quay" }}
            </button>
          </div>
          <button
            style="position: absolute; bottom: 1rem; right: 1rem"
            @click="ChangeCam"
            v-if="!isRecorded && !isRecording"
          >
            <img src="@/assets/images/video-call/rotage-cam.svg" alt="" />
          </button>
          <div
            style="position: absolute; top: 1rem; right: 1rem"
            class="font-large-1 text-primary"
            v-if="!isRecorded"
          >
            00:{{ countDown > 9 ? countDown : "0" + countDown }}
          </div>
        </div>
        <div class="main-bottom" v-if="isRecorded">
          <button class="video-call-btn" @click="ResetRecord">
            Thực hiện lại
          </button>
          <button class="video-call-btn" @click="SendVideo">Gửi Video</button>
        </div>
      </div>
    </div>
    <b-modal
      body-class="p-0"
      size="lg"
      hide-footer
      id="modal-end"
      title=""
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="bg-white p-2 rounded end-call-modal">
        <div class="d-flex justify-content-center p-2">
          <img
            src="@/assets/images/successCheck.png"
            alt=""
            style="width: 75px; height: 75px"
          />
        </div>
        <h4 class="font-large-2 text-center my-1 font-weight-bold">
          Thông báo
        </h4>
        <h4 class="font-medium-5 text-justify my-1">
          iTel đã tiếp nhận hồ sơ Đăng ký/Cập nhật TTTB. Hiện số thuê bao của
          Khách hàng đã sẵn sàng sử dụng. Trong 24 giờ, iTel sẽ gửi SMS thông
          báo kết quả xét duyệt hồ sơ và có quyền chặn khóa nếu hồ sơ không hợp
          lệ hoặc video giả mạo.
        </h4>
        <h4 class="font-medium-5 text-justify my-1" style="line-height: normal">
          <b> <u>Lưu ý</u>: CẦN KHỞI ĐỘNG LẠI</b> thiết bị để kiểm tra tín hiệu
          mạng. Nếu không truy cập được mạng, vui lòng bấm nút "Liên hệ CSKH" để
          được hỗ trợ.
        </h4>
        <div class="d-flex justify-content-center mt-2">
          <button
            class="btn btn-primary font-large-1"
            style="border-radius: 26.804px !important"
            @click="contactZalo"
          >
            Liên hệ CSKH
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { $ctx } from "@themeConfig";

export default {
  name: "send-video-vc",
  data() {
    return {
      isRecorded: false,
      isRecording: false,
      track: null,
      videoBase64: "",
      callId: "",
      countDown: 20,
    };
  },
  mounted() {
    if (this.$route.params.key) {
      if (
        (parseInt(this.$route.params.phone.slice(-3)) + 112) * 2 !=
        this.$route.params.key
      ) {
        this.$bvModal.show("modal-end");
      }
    }
    this.GetCallId(this.$route.params.phone);
    const intervalFooter = setInterval(() => {
      if (window.hiddenFooter) {
        window.hideChatBot(true);
        window.hiddenFooter(false);
        clearInterval(intervalFooter);
      }
    }, 1000);
    setTimeout(() => {
      this.StartCamera();
    }, 1000);
    if (
      !MediaRecorder.isTypeSupported("video/mp4") &&
      !MediaRecorder.isTypeSupported("video/webm") &&
      !MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
    ) {
      this.$toast.error(
        "Trình duyệt của bạn không hỗ trợ ghi video, vui lòng sử dụng trình duyệt khác!",
        { icon: true, closeButton: "button" }
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "video-call-dktt" || from.name == "video-call-cntt") {
      next();
      setTimeout(() => {
        window.location.reload();
      }, 800);
    } else {
      next();
    }
  },
  beforeDestroy() {
    window.hiddenFooter(true);
    window.hideChatBot(false);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    if (this.track) {
      this.track.getTracks().forEach((track) => {
        track.stop();
      });
    }
  },
  methods: {
    contactZalo() {
      window.open("https://zalo.me/3281327437324952111", "_blank");
    },
    GetCallId(phone) {
      axios.get($ctx.API_CRM_URL + "/get-call-id/" + phone).then((res) => {
        if (res.data.result.length > 0) {
          this.callId = res.data.result[0].ID;
        } else {
          this.$bvModal.show("modal-end");
        }
      });
    },
    StartCamera() {
      // navigator.mediaDevices
      //   .getUserMedia({
      //     video: {
      //       width: { ideal: 640, max: 640 },
      //       height: { ideal: 360, max: 360 },
      //     },
      //     audio: true,
      //   })
      //   .then((res) => {
      //     res.getTracks().forEach((track) => {
      //       track.stop();
      //     });
      //   });
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { ideal: 640, max: 1920 },
            height: { ideal: 360, max: 1080 },
          },
          audio: true,
        })
        .then((res) => {
          document.getElementById("videoLive").srcObject = res;
          this.track = res;
        })
        .catch(() => {
          this.$toast.error(
            "Không thể bắt đầu camera, vui lòng cấp quyền hoặc tải lại trang.",
            {
              icon: true,
              closeButton: "button",
            }
          );
        });
    },
    StartCountDown() {
      let countDownInterval = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          clearInterval(countDownInterval);
        }
      }, 1000);
    },
    async RecordVideo() {
      if (this.isRecording) return;
      let Vue = this;
      let isPermissionMic = false;
      let isPermissionCam = false;
      let options = { mimeType: "video/webm; codecs=vp9" };
      await navigator.permissions
        .query({ name: "microphone" })
        .then(function (result) {
          // console.log("mic", result.state);
          if (result.state == "granted") {
            isPermissionMic = true;
          } else if (result.state == "denied" || result.state == "prompt") {
            isPermissionMic = false;
          }
        });
      await navigator.permissions
        .query({ name: "camera" })
        .then(function (result) {
          // console.log("cam", result.state);
          if (result.state == "granted") {
            isPermissionCam = true;
          } else if (result.state == "denied" || result.state == "prompt") {
            isPermissionCam = false;
          }
        });
      if (document.getElementById("videoLive").srcObject === null) {
        await this.StartCamera();
        return;
      }

      if (!isPermissionMic && !isPermissionCam) {
        this.$toast.error(
          "Không thể bắt đầu camera, vui lòng cấp quyền hoặc tải lại trang",
          {
            icon: true,
            closeButton: "button",
          }
        );
        return;
      }

      this.isRecording = true;
      if (MediaRecorder.isTypeSupported("video/webm; codecs=vp9")) {
        options = { mimeType: "video/webm; codecs=vp9" };
      } else if (MediaRecorder.isTypeSupported("video/webm")) {
        options = { mimeType: "video/webm" };
      } else if (MediaRecorder.isTypeSupported("video/mp4")) {
        options = { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
      } else {
        console.error(
          "Trình duyệt của bạn không hỗ trợ ghi video, vui lòng sử dụng trình duyệt khác!"
        );
      }
      const mediaRecorder = new MediaRecorder(
        document.getElementById("videoLive").srcObject,
        options
      );
      mediaRecorder.start();
      this.StartCountDown();
      setTimeout(() => {
        mediaRecorder.stop();
        this.isRecording = false;
      }, 21000);
      mediaRecorder.addEventListener("dataavailable", (event) => {
        var reader = new FileReader();
        reader.readAsDataURL(event.data);
        this.isRecorded = true;
        Vue.track.getTracks().forEach((track) => {
          track.stop();
        });
        Vue.videoBase64 = event.data;
        document.getElementById("videoRecorded").src = URL.createObjectURL(
          event.data
        );
      });
    },
    ResetRecord() {
      this.isRecorded = false;
      this.countDown = 20;
      this.StartCamera();
    },
    async ChangeCam() {
      let listCam = [];
      let currentCam = null;
      let nextCam = null;
      await this.track.getTracks().forEach((track) => {
        track.stop();
      });
      await this.track.getTracks().forEach((track) => {
        if (track.kind === "video") {
          currentCam = track;
        }
      });
      await navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.kind === "videoinput") {
            listCam.push(device);
          }
        });
      });
      listCam.findIndex((cam, index) => {
        if (cam.label === currentCam.label) {
          if (index < listCam.length - 1) {
            nextCam = listCam[index + 1];
          } else {
            nextCam = listCam[0];
          }
        }
      });
      // console.log("listCam-->", listCam);
      // console.log("currentCam-->", currentCam);
      // console.log("next-->", nextCam);
      await navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: {
              exact: nextCam.deviceId,
            },
          },
          audio: true,
        })
        .then((res) => {
          document.getElementById("videoLive").srcObject = res;
          this.track = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SendVideo() {
      console.log(this.videoBase64);
      if (this.videoBase64 == "") {
        this.$toast.error("Vui lòng quay video trước khi gửi!", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.loading(true);
      const formData = new FormData();
      formData.append("files", this.videoBase64);
      formData.append("id", this.callId);
      formData.append("code", "");
      axios
        .post($ctx.API_CRM_URL + "/save_video_busy_1", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading(false);
          this.$toast.success("Gửi video thành công!", {
            icon: true,
            closeButton: "button",
          });
          this.$bvModal.show("modal-end");
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error("Gửi video thất bại!", {
            icon: true,
            closeButton: "button",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.modal-content {
  border-radius: 1.5rem;
}
#remoteTrack {
  width: 100%;

  video {
    width: 100%;
  }

  &:has(video) {
    height: 100%;
    background: #fff;
    border-radius: 13.778px;
    display: flex;
    align-items: center;
  }
}

#localTrack {
  width: 100%;

  video {
    width: 100%;
  }

  &:has(video) {
    height: 100%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
.bg-green {
  background: #28a745 !important;
}

.record-button {
  background: #ed1f24;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 26.804px;
  cursor: pointer;
  margin-top: 10px;
  height: 50px;
}

.img-video-call {
  width: 80%;
}

.end-call-modal {
  //border: 2px solid #ffbefe;
}

.full-area {
  width: 100% !important;
  height: 100% !important;
}

@keyframes d1 {
  0% {
    transform: scale3d(1, 1, 1);
  }

  70% {
    transform: scale3d(2, 2, 2);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes d2 {
  0% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  30% {
    transform: scale3d(2, 2, 2);
  }

  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  80% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

@keyframes d3 {
  0% {
    transform: scale3d(2, 2, 2);
  }

  60% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(2, 2, 2);
  }
}

.vc-page-bg {
  background: white;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  min-width: 350px;

  h2 {
    color: black;
  }

  .main-title {
    padding: 33px 38px 25px;
    color: #393939;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
  }

  .main-body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid #d9d9d9;
    padding: 15px 16px;

    .vc-modal {
      //width: min-content;
      border-radius: 13.778px;
      background: #fff;
      box-shadow: 0px 6.88889px 68.88891px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 40px 50px;

      .wall-bg {
        width: 520px;
        height: 181px;
        border-radius: 14px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .detail-instructions {
        margin-top: 21px;
        margin-bottom: 21px;
        padding-inline: 10px;

        .title-instruction {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          color: #151522;
          margin-bottom: 20px;
        }

        ul {
          li {
            color: #151522;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            list-style-type: none;
          }
        }
      }

      .desc-instructions {
        column-gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 91px;

        .desc-detail-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;

          .desc-face {
            width: 73px;
            height: 73px;
            border-radius: 73px;
            display: flex;
            justify-content: center;
            align-items: center;

            .img {
              width: 100%;
              height: 100%;
            }
          }

          .desc-x {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            img {
              width: 25px;
              height: 25px;
            }
          }

          .desc-text {
            color: #373737;
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            width: 100%;
            white-space: pre-wrap;
          }
        }
      }
    }

    .vc-show {
      width: 632px;
      display: flex;
      flex-direction: column;

      .main-video {
        width: 100%;
        height: 730px;
        border-radius: 13.778px;
        // background-image: url("../assets/images/video-call/video-img.png");
        // background-size: 100% 100%;
        background: black;
        position: relative;

        .local-track-container__before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // z-index: -1;
        }

        .fade-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          background: linear-gradient(
            rgb(255 255 255 / 2%),
            rgb(255 255 255 / 28%)
          );
        }

        .connecting {
          position: absolute;
          top: 52px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          display: flex;
          flex-direction: column;
          align-items: center;

          .caller-avatar {
            width: 180px;
            height: 180px;
            border-radius: 180px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .connecting-text {
            margin-top: 39px;
            color: #fff;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .dots {
            display: flex;
            align-items: center;
            margin-top: 20px;
            column-gap: 20px;

            .dot-1 {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background: white;
              animation: d3 1400ms;
              animation-iteration-count: infinite;
            }

            .dot-2 {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background: white;
              animation: d2 1400ms;
              animation-iteration-count: infinite;
            }

            .dot-3 {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background: white;
              animation: 1400ms d1;
              animation-iteration-count: infinite;
            }
          }
        }

        .connected {
          z-index: 3;
          position: absolute;
          right: 15px;
          top: 11px;
          width: 140px;
          height: 165px;
          border-radius: 10px;
          background-image: url("../assets/images/video-call/connected-user.png");
          background-size: 100% 100%;

          .local-track-container {
            position: relative;
            width: 140px;
            height: 165px;
          }

          video {
            width: 100%;
            height: 100%;
          }
        }

        .bottom-controls {
          position: absolute;
          bottom: 43px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 20px;
          background: #1d1d1d9d;
          display: flex;
          align-items: center;
          padding: 13px 0;
          z-index: 3;

          .control-item {
            height: 32px;
            margin-inline: 18px;
            cursor: pointer;

            img {
              height: 100%;
            }

            &.exit-call {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              background: #ed1f24;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 17px;
                height: 17px;
              }
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }

        .sign-container-modal {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .sign-modal {
            width: calc(100% - 76px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 40px;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .sign-area {
              width: calc(100% - 76px);
              border-radius: 10px;
              border: 1px solid #d9d9d9;
              background: #fff;
              height: 276px;
            }

            .sign-info {
              margin-top: 20px;
              width: calc(100% - 76px);
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }

            .sign-btn-controls {
              margin-top: 25px;
              width: calc(100% - 116px);
              column-gap: 25px;
              grid-template-columns: 1fr 1fr;
              margin-bottom: 34px;

              .sign-btn {
                width: 100%;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }
            }
          }
        }

        .contract-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .contract-modal {
            width: calc(100% - 220px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 21px;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .your-sign-show {
              width: calc(100% - 32px);
              border-radius: 10px;
              border: 1px solid #d9d9d9;
              background: #fff;
              height: 378px;
              overflow-y: auto;

              img {
                width: 100%;
              }
            }

            .sign-view-controls {
              margin-top: 30px;
              margin-bottom: 26px;
              display: flex;
              justify-content: center;
              align-items: center;

              .close-sign-view-btn {
                padding-inline: 60px;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }
            }
          }
        }

        .success-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .success-modal {
            width: calc(100% - 220px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 21px;
              align-items: center;
              justify-content: flex-end;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .icon-desc {
              width: 130px;
              height: 130px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .text-info-modal {
              margin-top: 20px;
              margin-bottom: 20px;
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 19px;
              font-style: normal;
              font-weight: 700;
              line-height: 41px;
            }

            .desc-info-modal {
              width: calc(100% - 94px);
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;

              .heart-icon {
                font-size: large;
                color: red;
              }
            }

            .sign-view-controls {
              margin-top: 30px;
              margin-bottom: 26px;
              display: flex;
              justify-content: center;
              align-items: center;

              .close-sign-view-btn {
                padding-inline: 60px;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }
            }
          }
        }

        .fail-1-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .success-modal {
            width: calc(100% - 220px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 21px;
              align-items: center;
              justify-content: flex-end;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .icon-desc {
              width: 233px;
              height: 153px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .fail-info-text {
              margin-top: 20px;
              width: calc(100% - 100px);
              text-align: left;
              color: #373737;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }

            .text-info-modal {
              margin-top: 20px;
              margin-bottom: 20px;
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 19px;
              font-style: normal;
              font-weight: 700;
              line-height: 41px;
            }

            .desc-info-modal {
              width: calc(100% - 94px);
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;

              .heart-icon {
                font-size: large;
                color: red;
              }
            }

            .sign-btn-controls {
              margin-top: 25px;
              width: calc(100% - 50px);
              column-gap: 20px;
              grid-template-columns: 1fr 1fr;
              margin-bottom: 34px;

              .sign-btn {
                width: 100%;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }

              .sign-close-btn {
                width: 100%;
                border-radius: 26.804px;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ff2424;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                border: 1px solid #ff2424;
                cursor: pointer;

                &:hover {
                  background: #f4f4f4;
                }

                &:active {
                  background: #f4f4f4e1;
                }
              }
            }
          }
        }
      }

      .confirm-container {
        width: 100%;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sign-confirm-btn {
          color: #fff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Roboto;
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          border-radius: 26.8px;
          background: #ed1e23;
          padding: 15px 50px;
          cursor: pointer;

          &:hover {
            background: #d91d20;
          }

          &:active {
            background: #b4191b;
          }
        }
      }
    }
  }

  .main-bottom {
    margin-top: 19px;
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    .video-call-btn {
      border-radius: 26.804px;
      background: #ed1f24;
      width: 250px;
      height: 48px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 10.722px;

      &:hover {
        background: #d91d20;
      }

      &:active {
        background: #b4191b;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .vc-page-bg {
    color: black;

    .main-title {
      padding: 37px 10px 30px 47px;
      color: #393939;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      text-transform: uppercase;
    }

    .main-body {
      align-items: center;
      height: fit-content;

      .vc-modal {
        width: 100%;
        border-radius: 10px;
        background: #fff;
        padding: 16px 20px 20px;

        .wall-bg {
          width: 100%;
          height: 189px;
          border-radius: 17px;

          > img {
            width: 100%;
            height: 100%;
            object-fit: none;
            object-position: center;
          }
        }

        .detail-instructions {
          margin-top: 24px;
          padding-inline: 0;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;

          .title-instruction {
            margin-bottom: 15px;
          }
        }

        .desc-instructions {
          padding-inline: 0 !important;
        }

        .desc-text {
          max-width: 120px;
        }
      }

      .vc-show {
        width: 100%;
        height: 655px;
        display: flex;
        flex-direction: column;

        .main-video {
          .connecting {
            .caller-avatar {
              width: 120px;
              height: 120px;
              border-radius: 120px;
              overflow: hidden;
            }

            .connecting-text {
              margin-top: 28px;
              font-size: 20px;
              text-align: center;
            }
          }

          .bottom-controls {
            bottom: 19px;
          }
        }
      }
    }

    .main-bottom {
      margin-top: 30px;

      .video-call-btn {
        width: 150px;
      }
    }
  }
}

@media screen and (max-width: 532px) {
  .main-title {
    padding-inline: 0 !important;
    text-align: center;
  }

  .header-modal {
    padding-left: 25px !important;
  }

  .sign-area {
    width: calc(100% - 40px) !important;
  }

  .main-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .sign-modal,
  .success-modal,
  .contract-modal {
    width: 100% !important;
  }

  .sign-btn-controls {
    width: calc(100% - 60px) !important;
  }

  .sign-btn-controls {
    row-gap: 10px;
    grid-template-columns: 1fr !important;
  }
}
</style>
